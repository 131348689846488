import {
  useRef,
  useState,
  useCallback,
  DragEvent,
  useContext,
  MouseEvent,
  memo,
} from "react";
import ReactFlow, {
  Node,
  Controls,
  MarkerType,
  addEdge,
  useReactFlow,
  useUpdateNodeInternals,
  useStoreApi,
  getConnectedEdges,
  Edge,
} from "reactflow";
import "./DrawingTool.scss";
import "reactflow/dist/style.css";
import CustomNode from "./DrawingToolItems/CustomNode";
import FloatingEdge from "./DrawingToolItems/FloatingEdge";
import CustomConnectionLine from "../../components/drawing-tool/CustomConnectionLine";
import {
  ArrowHeadSvg,
  ArrowHeadSvgSelected,
} from "../../components/drawing-tool/ArrowHeadSvg";
import { v4 as uuidv4 } from "uuid";
import { DrawingContext } from "./DrawingTool";
import { newNodes, proOptionsForReactFlow } from "../../config/constants";
import GroupNode from "./DrawingToolItems/GroupNode";
import {
  checkEdgeValidation,
  getNodePositionInsideParent,
  sortNodes,
  nodeValidationChecker,
} from "./utils";
import { nodeCatagories, nodeType } from "../../config/drawingConstants";
import { successMessage } from "../../helpers/ErrorHandler";
import { useCreateProcessGate } from "../../api/processGate/processGate";
import { Message } from "semantic-ui-react";
import { isEmpty } from "lodash";
import IngredientGroupNode from "./DrawingToolItems/IngredientGroupNode";
import Cursors from "./DrawingToolItems/Cursors";
import useCursorStateSynced from "./Hooks/useCursorStateSynced";
import ProcessGateFormModal from "./UpdateNodes/ProcessGate/components/ProcessGateFormModal";


const nodeTypes = {
  special: CustomNode,
  farmGate: memo(GroupNode),
  processGate: memo(GroupNode),
  ingredientGate: IngredientGroupNode,
};

const edgesTypes = {
  special: FloatingEdge,
};

//create process gate
const defaultEdgeOptions = {
  style: { strokeWidth: 3, stroke: "var(--mappingArrow)" },
  type: "special",
  markerEnd: {
    strokeWidth: 2,
    width: 20,
    height: 20,
    type: MarkerType.Arrow,
    color: "var(--mappingArrow)",
  },
};

const connectionLineStyle = {
  strokeWidth: 2,
  stroke: "var(--mappingArrow)",
};

const rfStyle = {
  backgroundColor: "#FFF",
};

const CanvasMain = () => {
  // State Variable
  const {
    chartNodes,
    chartEdges,
    productId,
    salesUnitId,
    setChartNodes,
    setChartEdges,
    onEdgesChange,
    onNodesChange,
    updateChartGroup,
    setNodeItem,
    saveDrawing,
  } = useContext(DrawingContext);

  const reactFlowWrapper = useRef<any>(null);
  const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);
  const [validationMessages, setValidationMessages] = useState<any[]>([]);
  const [deleteKeyCode, setDeleteKeyCode] = useState<any>("Backspace");
  const updateNodeInternals = useUpdateNodeInternals();
  const [cursors, onMouseMove] = useCursorStateSynced();
  const { getIntersectingNodes } = useReactFlow();
  const store = useStoreApi();
  const { mutate } = useCreateProcessGate();
  const [modalOpen, setModalOpen] = useState(false);
  const [newNode, setNewNode] = useState<any>();

  const validationForEdges = useCallback(
    (connection: any) => {
      const { isValid, validationMessage } = checkEdgeValidation(
        connection,
        chartEdges,
        chartNodes
      );
      if (!isValid) {
        setValidationMessages([...validationMessages, ...validationMessage]);
      }
      return isValid;
    },
    [setValidationMessages, validationMessages, chartNodes, chartEdges]
  );

  const onConnect = useCallback(
    (connection: any) => {
      const isValid = validationForEdges(connection);
      if (isValid) {
        const newEdge = {
          ...connection,
          type: "special",
          data: { endLabel: "test" },
        };
        setChartEdges(addEdge(newEdge, chartEdges));
      }
    },
    [chartEdges, chartNodes, validationForEdges]
  );

  //save transport node data
  const saveProcessGateData = (nodeItem: any, processData: any) => {
    const updatedNode = {
      ...nodeItem,
      data: {
        ...nodeItem?.data,
        reference: processData,
      },
    };
    const updatedChartNodes = [...chartNodes, updatedNode];
    setChartNodes(updatedChartNodes);
    setNodeItem(updatedNode);
    saveDrawing(chartEdges, updatedChartNodes);
    successMessage("Create process gate successfully");
  };

  //create process gate data
  const createProcessGate = (
    processGateName: string,
    newNode: any,
    closeModal: () => void
  ) => {
    let processGate = {
      processGateName,
      productId,
      salesUnitId,
      userCompletedStage: "RECIPE",
      processing: [],
      internalTransportation: [],
      internalStorage: [],
      packaging: [],
    };
    mutate(processGate, {
      onSuccess(data) {
        saveProcessGateData(newNode, {
          processGateId: data._id,
          productId: data?.productId,
          salesUnitId: data?.salesUnitId,
        });
        closeModal();
      },
    });
  };

  // Add a farmGate to drawing
  const addFarmGate = useCallback(
    (farmGateNode: Node) => {
      const newNode: Node = {
        id: uuidv4(),
        type: nodeCatagories.special,
        data: {
          label: "Cultivation",
          icon: nodeType.Cultivar,
          description: "",
          reference: null,
        },
        parentNode: farmGateNode.id,
        extent: "parent",
        position: { x: 40, y: 40 },
        style: undefined,
      };
      setChartNodes([...chartNodes, farmGateNode, newNode]);
      updateNodeInternals(farmGateNode.id);
      updateNodeInternals(newNode.id);
    },
    [chartNodes, updateNodeInternals, setChartNodes]
  );

  //save new node
  const saveNewNodeData = useCallback(
    (newNode: any) => {
      if (newNode.type === nodeCatagories.processGate) {
        setNewNode(newNode);
        setModalOpen(true);
        return;
      }
      if (newNode.type === nodeCatagories.farmGate) {
        addFarmGate(newNode);
        return;
      }
      setChartNodes([...chartNodes, newNode]);
      updateNodeInternals(newNode.id);
    },
    [chartNodes, createProcessGate, updateNodeInternals, addFarmGate]
  );

  const onDragOver = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const addNewNodeInsideGroup = useCallback((position: any) => {
    const intersections = getIntersectingNodes({
      x: position.x,
      y: position.y,
      width: 100,
      height: 40,
    }).filter(
      (n) =>
        n.type === nodeCatagories.farmGate ||
        n.type === nodeCatagories.processGate
    );

    const groupNode = intersections[intersections.length - 1];
    if (groupNode) {
      // if we drop a node on a group node, we want to position the node inside the group
      const positionInGroup = getNodePositionInsideParent(
        {
          position,
          width: 100,
          height: 60,
        },
        groupNode
      ) ?? { x: 0, y: 0 };
      const parentNode = groupNode?.id;
      return {
        isInGroup: true,
        positionInGroup,
        parentNode,
        groupNode,
      };
    }
    return {
      isInGroup: false,
      positionInGroup: { x: 0, y: 0 },
      parentNode: undefined,
      groupNode: undefined,
    };
  }, []);

  const validationForNodes = useCallback(
    (node: Node, group: Node | undefined) => {
      const { isValid, validationMessage } = nodeValidationChecker(
        node,
        group,
        chartNodes
      );
      if (!isValid) {
        setValidationMessages([...validationMessages, validationMessage]);
      }
      return isValid;
    },
    [setValidationMessages, validationMessages, chartNodes, chartEdges]
  );

  const addNewNode = useCallback(
    (position: any, node: any) => {
      let nodeType = nodeCatagories.special;
      switch (node.id) {
        case nodeCatagories.farmGate:
          nodeType = nodeCatagories.farmGate;
          break;
        case nodeCatagories.processGate:
          nodeType = nodeCatagories.processGate;
          break;
      }
      let nodeStyle =
        nodeType !== nodeCatagories.special
          ? { width: 300, height: 250 }
          : undefined;
      const newNode: Node = {
        id: uuidv4(),
        type: nodeType,
        data: {
          label: node.cardHeader,
          icon: node.id,
          description: "",
          reference: null,
        },
        position,
        style: nodeStyle,
      };
      const { isInGroup, positionInGroup, parentNode, groupNode } =
        addNewNodeInsideGroup(position);
      if (isInGroup) {
        newNode.position = positionInGroup;
        newNode.parentNode = parentNode;
        newNode.extent = parentNode ? "parent" : undefined;
      }
      if (groupNode && groupNode?.type === nodeCatagories.ingredientGate) {
        return;
      }
      const isValid = validationForNodes(newNode, groupNode);
      if (!isValid) return;

      saveNewNodeData(newNode);
    },
    [
      reactFlowInstance,
      chartNodes,
      addNewNodeInsideGroup,
      validationForNodes,
      saveNewNodeData,
    ]
  );

  // delete edges and save drawing after deleted nodes
  const onNodesDelete = useCallback(
    (deletedNodes: Node[]) => {
      const remainingEdges = deletedNodes.reduce((acc, node) => {
        const connectedEdges = getConnectedEdges([node], chartEdges);
        const remainingEdge = acc.filter(
          (edge) => !connectedEdges.includes(edge)
        );
        return [...remainingEdge];
      }, chartEdges);
      const remainingNodes = chartNodes.filter(
        (cn) =>
          !deletedNodes.some((dn) => dn.id === cn.id || dn.id === cn.parentNode)
      );
      saveDrawing(remainingEdges, remainingNodes);
    },
    [chartNodes, chartEdges]
  );

  // add new node in to canvas
  const onDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current?.getBoundingClientRect();
      const nodeType = event.dataTransfer.getData("application/reactflow");

      // check if the dropped element is valid
      if (typeof nodeType === "undefined" || !nodeType) {
        return;
      }
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      addNewNode(position, JSON.parse(nodeType));
    },
    [reactFlowInstance, chartNodes, addNewNode]
  );

  // change node position in the canvas
  const onNodeDragStop = useCallback(
    (_: MouseEvent, node: Node) => {
      if (node.type !== nodeCatagories.special && !node.parentNode) {
        return;
      }
      const intersections = getIntersectingNodes(node).filter(
        (n) => n.type !== nodeCatagories.special
      );
      const groupNode = intersections[intersections.length - 1];
      if (groupNode && groupNode?.type === nodeCatagories.ingredientGate) {
        return;
      }
      const isValid = validationForNodes(node, groupNode);
      if (!isValid) return;
      // when there is an intersection on drag stop, we want to attach the node to its new parent
      if (intersections.length && node.parentNode !== groupNode?.id) {
        const nextNodes: Node[] = store
          .getState()
          .getNodes()
          .map((n) => {
            if (n.id === groupNode.id) {
              return {
                ...n,
                className: "",
              };
            } else if (n.id === node.id) {
              const position = getNodePositionInsideParent(n, groupNode) ?? {
                x: 0,
                y: 0,
              };
              n.extent = "parent";
              return {
                ...n,
                position,
                parentNode: groupNode.id,
                // we need to set dragging = false, because the internal change of the dragging state
                // is not applied yet, so the node would be rendered as dragging
                dragging: false,
              };
            }
            return n;
          })
          .sort(sortNodes);
        setChartNodes(nextNodes);
      }
    },
    [getIntersectingNodes, setChartNodes, store, validationForNodes]
  );

  const onNodeDrag = useCallback(
    (_: MouseEvent, node: Node) => {
      if (node.type !== nodeCatagories.special && !node.parentNode) {
        return;
      }
      const intersections = getIntersectingNodes(node).filter(
        (n) => n.type !== nodeCatagories.special
      );
      const groupClassName =
        intersections.length && node.parentNode !== intersections[0]?.id
          ? "active"
          : "";
      updateChartGroup(groupClassName, node);
    },
    [getIntersectingNodes, updateChartGroup]
  );

  const onNodeClick = useCallback(
    (e: any, node: Node) => {
      if (
        !isEmpty(node.data.reference) ||
        node.data.icon === nodeType.Cultivar
      ) {
        setDeleteKeyCode(null);
        return;
      }
      setDeleteKeyCode("Backspace");
    },
    [setDeleteKeyCode]
  );

  const onEdgeClick = useCallback(
    (e: any, edge: Edge) => {
      setDeleteKeyCode("Backspace");
    },
    [setDeleteKeyCode]
  );

  return (
    <div className="drawing-wrapper" ref={reactFlowWrapper}>
      {validationMessages.length > 0 ? (
        <div className="validation-box">
          <Message
            color="red"
            onDismiss={() => {
              setValidationMessages([]);
            }}
          >
            <Message.Header>These are not accepted!</Message.Header>
            <Message.List>
              {validationMessages.map((message) => (
                <Message.Item>{message}</Message.Item>
              ))}
            </Message.List>
          </Message>
        </div>
      ) : null}
      <ArrowHeadSvg />
      <ArrowHeadSvgSelected />
      <ReactFlow
        nodes={chartNodes}
        onNodesChange={onNodesChange}
        proOptions={proOptionsForReactFlow}
        edges={chartEdges}
        onNodeClick={onNodeClick}
        onEdgeClick={onEdgeClick}
        deleteKeyCode={deleteKeyCode}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onNodeDrag={onNodeDrag}
        onNodeDragStop={onNodeDragStop}
        style={rfStyle}
        nodeTypes={nodeTypes}
        edgeTypes={edgesTypes}
        onNodesDelete={onNodesDelete}
        onInit={setReactFlowInstance}
        onDrop={onDrop}
        onDragOver={onDragOver}
        onPointerMove={onMouseMove}
        selectNodesOnDrag={false}
        defaultEdgeOptions={defaultEdgeOptions}
        connectionLineComponent={CustomConnectionLine}
        connectionLineStyle={connectionLineStyle}
      >
        <Cursors cursors={cursors} />
        <Controls />
        <ProcessGateFormModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          newNode={newNode}
          updateProcessGate={() => {}}
          createProcessGate={createProcessGate}
        />
      </ReactFlow>
    </div>
  );
};

export default CanvasMain;
